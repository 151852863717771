import _ from 'lodash-es'
import moment from 'moment'
import { ReviewPage, ReviewService } from '../../services/ReviewService'

export class ApplicantHomeController {

    playerConfig: any
    review: ReviewService
    interestedFamiliesCount: any

    incompleteFeeSheets: any[]

    isApplicant: boolean
    isOfficeUser: boolean
    isGerman2018: boolean
    isUnapprovePaymentExists: boolean
    needTravelDocuments: boolean

    canExtend: boolean
    displayFlight: boolean
    displayENFlight: boolean
    displayFirstYearCertificate: boolean
    displaySecondYearCertificate: boolean
    completionCertificateUrl: string
    displayYear2Insurance: boolean
    applicationInstance;
    systemAnnouncements;
    displayDOSLetter: boolean
    isAWL00: boolean;

    showFeeSheets: boolean
    showRedCrossCert: boolean = false;
    referralUrl: string

    static $inject = ['$sce', 'Auth', 'ReviewService', 'InterestedHostFamiliesDetails', 'EditFormStatus', 'dialogs', 'CompletionCertificate', 'DOSLetter', 'ApplicationPlacement', 'applicationId', 'applicationObservable', 'applicationStatus', '$state', 'Finance', '$window', 'USAuPairs', 'SystemAnnouncements', 'FeeSheetInfo', 'ApplicationUploads', 'localStorageService', 'Referral', '$stateParams'];
    constructor($sce, private Auth, ReviewService, InterestedHostFamiliesDetails, private EditFormStatus, private dialogs, private CompletionCertificate, private DOSLetter, ApplicationPlacement, private applicationId, private applicationObservable, private applicationStatus, private $state, Finance, private $window, private USAuPairs, private SystemAnnouncements, private FeeSheetInfo, ApplicationUploads, localStorageService, private Referral, $stateParams) {
        this.isApplicant = (Auth.user.role === 'APP')
        this.isOfficeUser = Auth.user.isOfficer;

        this.playerConfig = {
            sources: [
                { src: $sce.trustAsResourceUrl("http://campamericalive.s3.amazonaws.com/static/cad_video/PostIntVideo1.mp4"), type: 'video/mp4' }
            ],
            plugins: {
                poster: 'http://campamericalive.s3.amazonaws.com/static/cad_video/PostIntVideo1.png'
            }
        }
        this.systemAnnouncements = SystemAnnouncements.current();
        if (this.isApplicant) {
            this.review = ReviewService(this.applicationId, Auth.user.userId);
            // Get AP todo from US side  
            if (Auth.user.usAccessToken.length) {
                USAuPairs.canAupairExtend({ aupairId: Auth.user.usAuPairId }, (response) => {
                    if (response.data) {

                        // System was giving an error for TBM AP's as they dont have Match
                        if (response.data.isOkToShowExtensionFlight) {
                            this.USAuPairs.getENFlightData({
                                aupairId: this.Auth.user.usAuPairId
                            })
                                .$promise
                                .then((result) => {
                                    var tthfDepNumber = (result.data && result.data.match[0] && result.data.match[0].tthfDepNumber) || null;

                                    // If Ticket has been issued
                                    if (tthfDepNumber) {
                                        this.displayENFlight = false;
                                    }
                                    else
                                        this.displayENFlight = response.data.isOkToShowExtensionFlight;
                                });
                        }

                        // Display AWL00 ToDo only when EOY Visa Status is either 
                        this.USAuPairs.getVisaStatus({
                            aupairId: this.Auth.user.usAuPairId
                        })
                            .$promise
                            .then((result) => {
                                var visaStatus = (result.data.auPairVisaStatus.eoyVisaChangeProofStatus || 'E'); // using E as empty

                                if (response.data.apstatusCode == 'AWL' && response.data.apStatusReasonCode == '00' && (visaStatus == 'P' || visaStatus == 'N')) {
                                    this.isAWL00 = true;
                                }
                            });

                        this.canExtend = response.data.isEligibleToExtend;
                        this.displayFlight = response.data.isOkToShowHomeFlight;
                        this.displayFirstYearCertificate = response.data.isEligibleToDownloadYear1Certificate;
                        this.displaySecondYearCertificate = response.data.isEligibleToDownloadYear2Certificate;
                        this.displayYear2Insurance = response.data.isOktoShowInsurance;
                        this.displayDOSLetter = response.data.showParticipatationLetter;

                    }
                });
            }

        } else {
            let referenceId = null, itemId = null, isShowAsApplicant = true;
            this.review = ReviewService(this.applicationId, Auth.user.userId, Auth.user.role, referenceId, itemId, isShowAsApplicant);
            if (this.isOfficeUser) {
                USAuPairs.canAupairExtend({ aupairId: Auth.user.usAuPairId }, (response) => {
                    if (response.data) {
                        this.displayFirstYearCertificate = response.data.isEligibleToDownloadYear1Certificate;
                        this.displaySecondYearCertificate = response.data.isEligibleToDownloadYear2Certificate;
                    }
                });
            }
        }

        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;

            if (this.isApplicant && $stateParams.logInRedirect) {
                $stateParams.logInRedirect = false;
                if (applicationInstance.status === 'OLA') {
                    this.$state.go('app.applications.inst.highlights', { applicationId: this.applicationId });
                }
                else if (applicationInstance.status === 'TBM') {
                    this.$state.go('app.placement.interestedHF', { applicationId: this.applicationId });
                }
                else if (applicationInstance.status === 'PLC') {
                    this.$state.go('app.placement.placementDetails', { applicationId: this.applicationId });
                }
            }

            ApplicationPlacement.placementInfo({ applicationId: this.applicationId }, (placementInfo) => {
                this.isGerman2018 = applicationInstance.agencyId === 1210 && placementInfo.arrivalDate >= new Date(2018, 0);

                // TODO - uncomment - temporary commented 
                this.needTravelDocuments = placementInfo.arrivalDate <= moment().subtract(7, 'months').toDate();


                this.FeeSheetInfo.query({ applicationId: this.applicationId })
                    .$promise
                    .then((results) => {

                        this.showFeeSheets = this.applicationInstance.status === 'OLA' || this.applicationInstance.status === 'AWA' || this.applicationInstance.status === 'TBM' || this.applicationInstance.status === 'PLC'

                        if (placementInfo.arrivalDate) {
                            const arrivalDateYear = placementInfo.arrivalDate.getFullYear();
                            this.incompleteFeeSheets = results.filter(f => !f.isFeeSheetCompleted && f.validFrom.getFullYear() <= arrivalDateYear);
                        } else {
                            this.incompleteFeeSheets = results.filter(f => !f.isFeeSheetCompleted)
                        }
                    }, () => {
                        this.dialogs.error('Internal error', 'Error occured while getting Fee Sheet Information. Please try again after a while.')
                    });

            });

            // Only bother checking IHF, if this flag is true
            if (applicationInstance.status === 'TBM' || applicationInstance.status === 'REM' || applicationInstance.status === 'ACT') {
                this.interestedFamiliesCount = InterestedHostFamiliesDetails.count({
                    applicationId: this.applicationId
                });
            }

            if (applicationInstance.status === 'PLC' || applicationInstance.status === 'TBM') {
                ApplicationUploads.query({ applicationId: applicationId }, (data) => {
                    this.showRedCrossCert = !data.some(d => d.documentType === 53);
                });
            }

            if (Auth.user.role === 'OFF') {
                //this is for removing 'Pay your Au Pair in America Fees' from TODO list
                Finance.hasUnapprovedTransaction({ applicationId: this.applicationId }, (unapproveTrans) => {
                    this.isUnapprovePaymentExists = unapproveTrans.isUnapprovePaymentExists;
                })
            }
            Referral.query({ userId: applicationInstance.userId }, (data) => {
                this.referralUrl = `https://myaupairinamerica.com/register/ref/${data.referrerIdentifier}`;
            })
        })
    }

    showDocument(document) {
        if (document.documentType === 37) {
            return this.applicationInstance && this.applicationInstance.isFeeSheetDocExists && this.applicationInstance.status !== 'OLA';
        }
        return true;
    }

    showMenuItem(menu) {
        if (menu.type === 13 ) {
            return (this.applicationInstance && this.applicationInstance.isReturner);
        }

        return (menu.menuItem && menu.menuItem.name);
    }

    downloadCompletionCertificate(yearSelected) {

        if (this.Auth.user.role !== 'APP')
            return;

        this.CompletionCertificate.completionCertUrl({
            applicationId: this.applicationId,
            year: yearSelected
        }, (result) => {
            this.completionCertificateUrl = result.url;

            this.$window.open(result.url, '_blank');
        })
    }

    downloadDOSLetter(yearSelected) {
        this.DOSLetter.dosCertUrl({
            usApplicationId: this.Auth.user.usAuPairId
        }, (result) => {
            this.$window.open(result.url, '_blank');
        })
    }
    editForm() {
        if (!this.isApplicant) {
            this.gotoApplication();
            return;
        }

        //var url = $('#' + section).attr('href');

        // User can only call this method at TBM
        // if at this point 'canEditForm' is true, means user is at TBM and has not started editing the form.
        // we display message and let them know whats going to happen, when they click yes
        if (this.applicationStatus.canEditForm) {
            this.dialogs.confirm('Please Confirm', 'Clicking yes will make your form editable and will need to be checked again by office staff.')
                .result
                .then(() => {
                    let user = this.Auth.user;

                    let data = {
                        applicationId: this.applicationId,
                        author: user.firstName + ' ' + user.lastName,
                        makeFormEditable: true
                    };

                    this.EditFormStatus.update({ applicationId: this.applicationId }, data)
                        .$promise
                        .then((results) => {
                            if (_.some(results, (r: any) => r.success == false)) {
                                this.dialogs.error('Internal error', 'Error occured while processing your request. Please try again after a while.');
                            } else {
                                this.applicationObservable
                                    .refresh()
                                    .then(i => {
                                        this.gotoApplication();
                                    });
                            }
                        });
                });
        }
        else {
            this.gotoApplication();
        }
    }

    placementState() {
        return this.isApplicant ? "app.placement" : "app.applications.placement";
    }

    paymentsState() {
        return this.isApplicant ? "app.payments" : "app.applications.applicationPayments";
    }

    resourceState() {
        return this.isApplicant ? "app.resource" : "app.applications.resource";
    }

    gotoApplication() {

        for (let page of this.review.pages) {
            if (!page.hasNote) {
                this.$state.go(page.menuItem.state, { applicationId: this.applicationId });
                return;
            }
        }

        for (let page of this.review.pages) {
            if (!page.isComplete) {
                this.$state.go(page.menuItem.state, { applicationId: this.applicationId });
                return;
            }
        }

        for (let page of this.review.pages) {
            if (!page.isUploadComplete) {
                this.$state.go(page.menuItem.state, { applicationId: this.applicationId });
                return;
            }
        }

        // default
        this.$state.go("app.applications.inst.highlights", { applicationId: this.applicationId });

    }
}

angular
    .module('app')
    .controller('ApplicantHomeController', ApplicantHomeController)

